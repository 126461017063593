import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Tooltip } from 'recharts'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import PieChartStyle from './styled'

const DATA_DEFAULT_STATE = [{ name: '', value: 1 }]

class GraphCounting extends React.Component {
  state = {
    activeIndex: 0,
    innerRadiusActive: 0,
    outerRadiusActive: 0,
  }

  renderSectors(data) {
    let output = null
    const { colors } = this.props
    if (this.props.countingTotal === 0) {
      output = <Cell fill="#2e2e2e" />
    } else {
      output = data.map((entry, index) => <Cell key={`${entry}${index}`} fill={colors[index % colors.length]} />)
    }
    return output
  }

  renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

    return (
      <g fill={'#000'} stroke="none">
        <circle cx={cx} cy={cy} r={'25.8%'} fill={'#2e2e2e'} stroke="none" />
        <NumberFormat
          value={this.props.countingTotal || 0}
          displayType="text"
          thousandSeparator={true}
          renderText={value => (
            <text x={cx} y={cy} dy={0} textAnchor="middle" data-test="lbl-vehicle-count-total-value" fill={'#f2f2f2'} className="totalvisitor-number">
              {value}
            </text>
          )}
        />
        <text x={cx} y={cy} dy={24} textAnchor="middle" fill={'#fff'} className="totalvisitor-label" data-test="lbl-vehicle-count-total">
          {this.props.countingText || ''}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          dy={100}
          innerRadius={innerRadius + this.state.innerRadiusActive}
          outerRadius={outerRadius + this.state.outerRadiusActive}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    )
  }

  renderTooltip = ({ active, payload }) => {
    let output = null
    if (active) {
      output = (
        <div className="tooltip">
          <div className="title">{payload[0].name}</div>
          <div className="value">{payload[0].value}</div>
        </div>
      )
      if (this.props.countingTotal === 0) {
        output = null
      }
    }
    return output
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      innerRadiusActive: 5,
      outerRadiusActive: 5,
    })
  }

  onPieLeave = () => {
    this.setState({
      innerRadiusActive: 0,
      outerRadiusActive: 0,
    })
  }

  renderPieChart() {
    const { data } = this.props
    let output = (
      <div className="piechart-component">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={this.renderActiveShape}
              data={this.props.countingTotal === 0 ? DATA_DEFAULT_STATE : data}
              cx={'50%'}
              cy={'50%'}
              innerRadius={'58%'}
              outerRadius={'72%'}
              paddingAngle={0}
              startAngle={270}
              endAngle={-90}
              stroke="none"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
              onMouseLeave={this.onPieLeave}
            >
              {this.renderSectors(data)}
            </Pie>
            <Tooltip content={this.renderTooltip} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
    if (this.props.loading) {
      output = <SkeletonAnimation id="piechart-skeleton-loading" className="loading" />
    }
    return <div className="piechart-wrapper flex align-items-center justify-center">{output}</div>
  }

  render() {
    return <PieChartStyle className={this.props.className}>{this.renderPieChart()}</PieChartStyle>
  }
}

GraphCounting.defaultProps = {
  language: 'EN',
  loading: false,
}

GraphCounting.propTypes = {
  language: PropTypes.string,
  className: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  countingTotal: PropTypes.number.isRequired,
  countingText: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      percentage: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool,
}
export default GraphCounting
