import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  .piechart-wrapper {
    .loading {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      margin: 30px 0 20px 0;
    }
    .piechart-component {
      width: 269px; /*269px to make pie 186px*/
      height: 269px; /*269px to make pie 186px*/
      .totalvisitor-number {
        font-family: 'Prompt';
        font-size: 36px;
        color: #f2f2f2;
      }
      .totalvisitor-label {
        font-family: 'Prompt';
        font-size: 12px;
        color: #f2f2f2;
      }
      .tooltip {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 84px;
        height: 48px;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px #e4eeea;
        background-color: #1e1e1e;
        padding: 8px 7px;
        margin: 0 5px;
        font-size: 12px;
        color: #fff;
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    .piechart-wrapper {
      .piechart-component {
        width: 236px;
        height: 236px;
        .loading {
          width: 162px;
          height: 162px;
          border-radius: 50%;
        }
        .totalvisitor-number {
          font-size: 36px;
          color: #f2f2f2;
        }
        .totalvisitor-label {
          font-size: 12px;
          color: #f2f2f2;
        }
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .piechart-wrapper {
      .piechart-component {
        .loading {
          width: 186px;
          height: 186px;
          border-radius: 50%;
        }
        .totalvisitor-number {
          font-size: 36px;
          color: #f2f2f2;
        }
        .totalvisitor-label {
          font-size: 12px;
          color: #f2f2f2;
        }
      }
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    .piechart-wrapper {
      .piechart-component {
        width: 260px;
        height: 260px;
        .loading {
          width: 186px;
          height: 186px;
          border-radius: 50%;
        }
        .totalvisitor-number {
          font-size: 36px;
          color: #f2f2f2;
        }
        .totalvisitor-label {
          font-size: 12px;
          color: #f2f2f2;
        }
      }
    }
  }
`
